import react from '../images/react.png';
import reactnative from '../images/reactnative.png';
import firebase from '../images/firebase.png';
import node from '../images/node.png';
import rails from '../images/ruby.png';
import stacks from '../images/stacks.png';
export default function Stacks() {
	return (
		<div className="stacksHolder">
			<div className="stacks">
				<h4>Tech stack includes</h4>
				<img src={react} className="stacksLogo" />
				<img src={reactnative} className="stacksLogo" />
				<img src={firebase} className="stacksLogo" />
				<img src={node} className="stacksLogo" />
				<img src={rails} className="stacksLogo" />
			</div>
			<div className="mergeStacks">
				<h4>Tech stack includes</h4>
				<img src={stacks} />
			</div>
		</div>
	);
}
