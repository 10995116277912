import Jira from '../images/graphic.png';
export default function Hero() {
	return (
		<section className="hero" id="home">
			<h4>Mobile App Development</h4>
			<h1>Work with an expert to launch your product.</h1>

			<img src={Jira} className="jira" alt={''} />
			<div className="darkBtn">Let's Talk</div>
		</section>
	);
}
