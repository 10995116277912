import Logo from '../images/logo.png';
export default function Footer() {
	const year = new Date().getFullYear().toString();
	return (
		<div className="footer">
			<img src={Logo} className="footerLogo" />
			<div>Copyright © {year} Zippax. All rights reserved.</div>
		</div>
	);
}
