import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { Menu, X } from 'react-feather';
import { slideInDown, fadeInRight } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import Logo from '../../images/logo.png';
import './style.css';
import MenuList from './menu.json';
const styles = {
	slideInDown: {
		animation: 'x 0.5s',
		animationName: Radium.keyframes(slideInDown, 'slideInDown')
	},
	fadeInRight: {
		animation: 'x 0.5s',
		animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
	}
};
export default function Header() {
	const [ active, setActive ] = useState('');
	const [ shadow, setShadow ] = useState(false);
	const [ showMenu, setMenu ] = useState(false);
	const [ showContact, setContact ] = useState(false);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			setShadow(window.scrollY > 100);
		});
	}, []);

	return (
		<div className={shadow ? 'header shadow' : 'header'}>
			<div className="headerContent">
				<div className="logoHolder">
					<img src={Logo} className="logo" alt="zippax" />
				</div>
				<div className="menuHolder">
					<ul>
						{MenuList.map((item, index) => (
							<li key={index}>
								<Link to={item.id} spy={true} smooth={true} onClick={() => setActive(item.id)} offset={-150}>
									{item.name}
									{active === item.id && <div className="active" />}
									{active === '' && item.id === 'home' && <div className="active" />}
								</Link>
							</li>
						))}
					</ul>
				</div>
				<div className="contactBtnHolder" onClick={() => [ setContact(true) ]}>
					<div className="contactBtn">Contact Me</div>
				</div>
				<div className="menuIconHolder">
					<Menu size={30} color="#fff" className="menuBtn" onClick={() => setMenu(true)} />
				</div>

				{showMenu && (
					<StyleRoot>
						<div className="mobileMenuHolder" style={styles.slideInDown}>
							<X size={30} color="#fff" className="closeBtn" onClick={() => setMenu(false)} />
							{MenuList.map((item, index) => (
								<Link to={item.id} spy={true} smooth={true} onClick={() => setActive(item.id)} offset={-150}>
									<div className="menuItem" onClick={() => setMenu(false)}>
										{' '}
										{item.name}
									</div>
								</Link>
							))}
							<div className="menuItem contactBtn center" onClick={() => [ setContact(true), setMenu(false) ]}>
								Contact Me
							</div>
						</div>
					</StyleRoot>
				)}
			</div>
			<StyleRoot>
				{showContact && (
					<div className="contact_me" style={styles.fadeInRight}>
						<div className="contactWrapper">
							<h2>Please fill out the form</h2>
							<input name="name" type="text" className="contactInput" autoComplete="off" placeholder="Your name" />
							<input
								name="email"
								type="text"
								className="contactInput"
								autoComplete="off"
								placeholder="Your email address"
							/>
							<textarea name="message" className="contactTextArea" placeholder="Please write your message ..." />
							<button className="contactBtn" style={{ marginTop: 30 }}>
								Send
							</button>
							<button
								className="contactBtn"
								style={{ marginTop: 30, marginLeft: 20, backgroundColor: '#f2f2f2' }}
								onClick={() => setContact(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				)}
			</StyleRoot>
		</div>
	);
}
