import Maeda from '../images/maedaAppCrop.png';
import MaedaLogo from '../images/maedaLogoIcon.png';
import TideUp from '../images/tideUpCrop.png';
import TideUpLogo from '../images/tideUpLogoIcon.png';
export default function Work() {
	return (
		<div className="worksHolder" id="work">
			<div className="works">
				<h3>MY Latest Creative Work</h3>
				<div className="worksCardHolder">
					<div className="workCard">
						<a href="https://apps.apple.com/us/app/id1542523997" target="_blank">
							<img src={Maeda} className="workImage" />
						</a>
						<div className="workCardFooter">
							<img src={MaedaLogo} className="appLogo" />
							<div className="details">
								<div className="appTxt">Maeda</div>
								<div className="appDescTxt">Food delivery application</div>
								<a href="https://apps.apple.com/us/app/id1542523997" target="_blank">
									<div className="show">Show</div>
								</a>
							</div>
						</div>
					</div>
					<div className="spacer-s" />
					<div className="workCard">
						<a href="https://apps.apple.com/sa/app/tideup/id1534942918" target="_blank">
							<img src={TideUp} className="workImage" />
						</a>
						<div className="workCardFooter">
							<img src={TideUpLogo} className="appLogo" />
							<div className="details">
								<div className="appTxt">TideUp</div>
								<div className="appDescTxt">Forecast & Tide application</div>
								<a href="https://apps.apple.com/sa/app/tideup/id1534942918" target="_blank">
									<div className="show">Show</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
