import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { fadeInDown } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { Scrollbars } from 'react-custom-scrollbars-2';
import db from './data/services.json';
const styles = {
	fadeInDown: {
		animation: 'x 0.5s',
		animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
	}
};
export default function ServiceDetails(props) {
	const { data } = props;
	const [ service, setService ] = useState({});
	const [ loaded, setLoaded ] = useState(false);

	useEffect(() => {
		getService();
	}, []);
	const getService = () => {
		const sdb = db.filter((i) => i.id === data.id);
		console.log(sdb);
		setService(sdb[0]);
		setLoaded(true);
	};
	return (
		<StyleRoot>
			{loaded && (
				<Scrollbars style={{ height: '100vh' }}>
					<div className="serviceDetailsContainer" style={styles.fadeInDown}>
						<div className="closeDetails" onClick={props.close}>
							<X color="#000" size={28} />
						</div>
						<div className="detailsContent">
							<h1>{service.title}</h1>
							{service.body.map((p, index) => (
								<div>
									<p key={index} style={{ marginBottom: p.marginBottom }}>
										{p.paragraph}
									</p>
									{p.image !== null && <img className="detailsImage" src={p.image} />}
								</div>
							))}
						</div>
						<div className="letsTalkHolder">
							<div className="contactBtnHolder">
								<div className="contactBtn centerBtn">Schedule a Metting</div>
							</div>
						</div>
					</div>
				</Scrollbars>
			)}
		</StyleRoot>
	);
}
