import { fadeInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import p1 from '../images/p1-1.jpg';
import p2 from '../images/p2-1.jpg';
import p3 from '../images/p3-1.jpg';
const styles = {
	fadeInUp: {
		animation: 'x 1.3s',
		animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
	}
};
export default function Proccess() {
	return (
		<section className="proccess">
			<h2>The Proccess</h2>
			<StyleRoot>
				<div className="proccess_card_holder">
					<div className="proccess_card" style={styles.fadeInUp}>
						<div className="step">1</div>

						<img src={p1} className="proccessImg" />

						<div className="mark" />
						<div className="proccessTxt">Solutions & Planning</div>
					</div>

					<div className="spacer" />

					<div className="proccess_card" style={styles.fadeInUp}>
						<div className="step">2</div>
						<img src={p2} className="proccessImg" />
						<div className="mark" />
						<div className="proccessTxt">UI / UX</div>
					</div>

					<div className="spacer" />

					<div className="proccess_card" style={styles.fadeInUp}>
						<div className="step">3</div>
						<img src={p3} className="proccessImg" />
						<div className="mark" />
						<div className="proccessTxt">Development</div>
					</div>
				</div>
			</StyleRoot>
		</section>
	);
}
