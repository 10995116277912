import React, { useEffect, useState } from 'react';
import '../css/style.css';
import Header from './header';
import Hero from './Hero';
import Proccess from './Proccess';
import Services from './Services';
import Stacks from './Stacks';
import Work from './Work';
import Footer from './Footer';
import ServiceDetails from './ServiceDetails';
function App() {
	const [ spinner, setSpinner ] = useState(true);
	const [ sd, setSd ] = useState(false);
	const [ servicesData, setServicesData ] = useState({});

	useEffect(() => {
		setTimeout(() => setSpinner(false), 1500);
	}, []);

	return (
		<div className={sd ? 'serviceBg' : 'app'}>
			{!sd ? (
				<div>
					<Header />
					<Hero />
					<Proccess />
					<Services showDetails={() => setSd(true)} data={setServicesData} />
					<Stacks />
					<Work />
					<Footer />
				</div>
			) : (
				// <div className="wait">Please Wait ...</div>
				<ServiceDetails close={() => setSd(false)} data={servicesData} />
			)}
		</div>
	);
}

export default App;
