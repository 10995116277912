import React, { useState } from 'react';
import { Maximize2 } from 'react-feather';

export default function Services(props) {
	const [ service, setService ] = useState(null);

	const updateService = (e) => {
		setService(e);
		props.data({
			id: e
		});
		props.showDetails();
	};
	return (
		<section id="services">
			<h2>Services</h2>

			<div className="serviceCardsHolder">
				<div className="serviceCard" onClick={() => updateService(1)}>
					<h4>Website Development</h4>
					<p>
						Let’s get your business set up in the digital space with a unique and intuitive website, a digital brand
						image for your physical business.
					</p>
					<span className="iconHolder">
						<Maximize2 color="#818181" size={18} />
					</span>
				</div>
				<div className="spacer-s" />
				<div className="serviceCard" onClick={() => updateService(2)}>
					<h4>Mobile Development</h4>
					<p>
						In the present world, mobile app is the biggest marketplace for every business and we will help you create
						The biggest shop in this market.
					</p>
					<span className="iconHolder">
						<Maximize2 color="#818181" size={18} />
					</span>
				</div>
				<div className="spacer-s" />
				<div className="serviceCard" onClick={() => updateService(3)}>
					<h4>UI / UX Design</h4>
					<p>
						The first thing that a user connects to is design, whether it’s a website, app, or software. Solidify user
						adoption with our UI/UX services.
					</p>
					<span className="iconHolder">
						<Maximize2 color="#818181" size={18} />
					</span>
				</div>
			</div>
		</section>
	);
}
